body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo img{
  height: 33px;
}

.header_user {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.headerFlex {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
}

.out {
  color: #2d8cff;
  font-size: 14px;
  margin-left: 20px;
}
.login{
  width: 100%;
  height: 100vh;
  background-color: #f0f3f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login_tab{
  display: flex;
  margin-top: 30px;
}
.login_tab_item{
  font-size: 15px;
  margin-right: 20px;
  line-height: 2.5;
  cursor: pointer;
}
.cur{
  color: #2d8cff;
  border-bottom: 2px solid #2d8cff;
}
.login_rememberMe{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 20px;
}
.add_user{
  width: 100%;
  height: 100vh;
  background-color: #f0f3f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.add_user_box{
  width: 380px;
  min-height: 500px;
  background-color: #fff;
  padding: 20px;
  padding-bottom: 60px;
}
.add_user_title{
  font-size: 20px;
}